import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitialStateSuccess } from 'redux/actions/fetch';
import { resetStateSuccess } from 'redux/actions/reset';
import { operationFetchBanners } from 'redux/operations/fetch';

const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_INIT':
			return { ...state, isLoading: true, isError: false };
		case 'FETCH_SUCCESS':
			return { ...state, isLoading: false, isError: false };
		case 'FETCH_FAILURE':
			return { ...state, isLoading: false, isError: true, error: action.payload };
		default:
			throw new Error();
	}
};

const useInit = () => {
	const [state, dispatchReducer] = useReducer(dataFetchReducer, { isLoading: false, isError: false });
	const [isInitiated, setIsInitiated] = useState();
	const versionState = useSelector(({ version }) => version);
	const dispatch = useDispatch();
	let endpoint = `${process.env.REACT_APP_API}/ranking/edition/ranking`;
	endpoint += `-${process.env.REACT_APP_THEME === 'LICEA' ? 'liceow' : 'technikow'}`;
	endpoint += `-${process.env.REACT_APP_EDITION}`;

	useEffect(() => {
		let didCancel = false;
		if (isInitiated) {
			const fetchData = async () => {
				dispatchReducer({ type: 'FETCH_INIT' });
				try {
					await axios
						.get(endpoint, {
							accept: 'application/json',
						})
						.then((response) => {
							dispatchReducer({ type: 'FETCH_SUCCESS' });
							if (versionState !== (response.data.version ?? null)) {
								dispatch(resetStateSuccess());

								if (response.data?.banners?.global) {
									dispatch(operationFetchBanners({ hash: response.data.banners.global, id: 'global' }));
								}

								if (response.data?.banners?.bannersTop) {
									dispatch(
										operationFetchBanners({ hash: response.data.banners.bannersTop, id: 'bannersTop' }),
									);
								}

								if (response.data?.banners?.bannersBottom) {
									dispatch(
										operationFetchBanners({
											hash: response.data.banners.bannersBottom,
											id: 'bannersBottom',
										}),
									);
								}

								if (response.data?.banners?.bannnersFounders) {
									dispatch(
										operationFetchBanners({
											hash: response.data.banners.bannnersFounders,
											id: 'bannnersFounders',
										}),
									);
								}

								if (response.data?.banners?.billboards) {
									dispatch(
										operationFetchBanners({ hash: response.data.banners.billboards, id: 'billboards' }),
									);
								}
							}

							dispatch(fetchInitialStateSuccess(response.data));

							setIsInitiated();
						})
						.catch((error) => {
							if (!didCancel) {
								let errorData = 'error';
								if (error.response && error.response.data && error.response.data.message) {
									errorData = error.response.data.message;
								}
								dispatchReducer({ type: 'FETCH_FAILURE', payload: errorData });
								setIsInitiated();
							}
						});
				} catch (error) {
					if (!didCancel) {
						dispatchReducer({ type: 'FETCH_FAILURE', payload: 'error' });
						setIsInitiated();
					}
				}
			};
			fetchData();
		}
		return () => {
			didCancel = true;
		};
	}, [isInitiated]);

	const fetchInit = () => {
		setIsInitiated(true);
	};

	return [state, fetchInit];
};
export default useInit;
